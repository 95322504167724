<template>
  <div>
    <MediaUploadComponent />
    <TopicsGeneratedComponent />
  </div>
</template>

<script>
import MediaUploadComponent from '../DataUpload/GenerateTopics.vue';
import TopicsGeneratedComponent from '../DataUpload/Topics.vue';

export default {
  components: {
    MediaUploadComponent,
    TopicsGeneratedComponent,
  },
};
</script>

<style scoped>
</style>
