<template>
  <div class="min-h-screen bg-black pb-16">
    <div class="flex items-center justify-between secondary-bg text-white py-4 px-16 fixed top-0 left-0 w-full z-50">
      <div class="flex items-center">
        <img :src="logo" alt="Company Logo" class="w-40 h-auto" />
        <div class="h-6 border-l border-gray-500 mx-4"></div>
        <span class="text-sm font-medium">{{ name }}</span>
      </div>
      <div class="relative inline-block text-left">
        <div class="flex items-center text-white py-2 cursor-pointer" @click="toggleDropdown">
          <div class="flex items-center justify-center w-8 h-8 bg-white text-gray-900 font-bold rounded-full mr-3">
            {{ getInitials(name) }}
          </div>
          <div class="flex flex-col">
            <span class="text-sm font-semibold">{{ name }}</span>
          </div>
          <div class="ml-2 text-xs">&#9662;</div>
        </div>
        <ul v-if="dropdownVisible"
          class="absolute right-0 mt-2 w-28 secondary-bg text-white border border-gray-600 rounded-md shadow-lg z-50">
          <li class="px-4 py-1 text-sm hover:bg-purple-600 hover:text-white cursor-pointer" @click="logout">
            Logout
          </li>
        </ul>
      </div>
    </div>

    <div class="flex justify-between items-center mt-16 p-16">
      <div>
        <h1 class="text-2xl font-bold text-white">Good Morning, {{ name }}!</h1>
        <p class="text-sm text-gray-400">Welcome back! Let's get started.</p>
      </div>
      <div>
        <div
          class="relative flex justify-between items-center w-full rounded-full p-1 my-2 space-x-4 secondary-bg border border-purple-600">
          <button @click="selectedOption = 'Data Upload'" :class="[
            'px-6 py-2 h-full flex items-center justify-center rounded-full text-sm font-medium transition-all',
            selectedOption === 'Data Upload' ? 'bg-purple-600 text-white' : 'text-gray-700',
          ]">
            Data Upload
          </button>
          <button @click="selectedOption = 'Dashboard'" :class="[
            'px-6 py-2 h-full flex items-center justify-center rounded-full text-sm font-medium transition-all',
            selectedOption === 'Dashboard' ? 'bg-purple-600 text-white' : 'text-gray-700',
          ]">
            Dashboard
          </button>
          <button @click="selectedOption = 'Image Generation'" :class="[
            'px-6 py-2 h-full flex items-center justify-center rounded-full text-sm font-medium transition-all',
            selectedOption === 'Image Generation' ? 'bg-purple-600 text-white' : 'text-gray-700',
          ]">
            Image Generation
          </button>
        </div>
      </div>
    </div>

    <div class="mx-16">
      <DataUploadTab v-if="selectedOption === 'Data Upload'" />
      <DashboardTab v-if="selectedOption === 'Dashboard'" />
      <ImageGenerationTab v-if="selectedOption === 'Image Generation'" />
    </div>
  </div>
</template>

<script>
import DataUploadTab from "@/components/Tabs/DataUploadTab.vue";
import DashboardTab from "@/components/Tabs/DashboardTab.vue";
import ImageGenerationTab from "@/components/Tabs/ImageGenerationTab.vue";

export default {
  name: "DataUpload1",
  components: {
    DataUploadTab,
    DashboardTab,
    ImageGenerationTab,
  },
  data() {
    return {
      selectedOption: "Data Upload", // Default selected option
      logo: require("@/assets/img/fe/kearney-logo-white.png"), // Company logo
      name: "",
      dropdownVisible: false
    };
  },
  created() {
    this.getLoginData();
  },
  methods: {
    getInitials(name) {
      if (!name) return "";
      return name
        .split(" ")
        .map(word => word[0].toUpperCase())
        .join("");
    },
    getLoginData() {
      const loginData = JSON.parse(localStorage.getItem("loginData"));
      if (loginData && loginData.name) {
        this.name = loginData.name;
      }
    },
    toggleButton(type) {
      this.selectedOption = type;
    },
    toggleDropdown() {
      this.dropdownVisible = !this.dropdownVisible;
    },
    logout() {
      alert("Logged out");
      this.dropdownVisible = false;
    },
  }
};
</script>

<style>
.secondary-bg {
  background: #1e1e1e;
}
</style>
