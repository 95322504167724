<template>
  <div class="bg-black text-white">
    <div class="w-1/3 flex">
      <button
        class="rounded-tl-md rounded-tr-md flex-1 py-4 text-center transition mr-2 border border-gray-700 hover:secondary-bg"
        :class="{
          'border-b secondary-bg': activeTab === 'generate',
          'hover:border-gray-500': activeTab !== 'generate'
        }" @click="activeTab = 'generate'">
        Image Generation
      </button>
      <button
        class="flex-1 rounded-tl-md rounded-tr-md py-4 text-center transition border border-gray-700 hover:secondary-bg"
        :class="{
          'border-b-0 secondary-bg': activeTab === 'edit',
          'hover:border-gray-500': activeTab !== 'edit'
        }" @click="activeTab = 'edit'">
        Edit Image
      </button>
    </div>
    <div class="border border-gray-700">
      <div v-if="activeTab === 'generate'" class="px-8 pb-8 my-4 space-y-4 pt-8">
        <h1 class="text-2xl font-bold">Title of the Product</h1>
        <p class="text-gray-400">
          The Cordless Drill Kit is the ultimate solution for all your DIY projects and home improvement tasks.
          Powered by a robust 20V lithium-ion battery, this drill offers unmatched convenience, reliability, and
          power, allowing you to tackle everything from basic drilling to more advanced tasks with ease.
        </p>
        <form @submit.prevent="handleSubmit" class="flex items-center space-x-4 pt-6">
          <!-- Product Name Field (25% width) -->
          <div class="flex flex-col w-1/4">
            <label for="productName" class="text-gray-300 mb-2">Product Name</label>
            <input id="productName" v-model="form.productName" type="text"
              class="p-3 rounded secondary-bg text-white border border-gray-700 focus:outline-none focus:ring-2 focus:ring-purple-500" />
          </div>

          <!-- Prompt Field (50% width) -->
          <div class="flex flex-col w-1/2">
            <label for="prompt" class="text-gray-300 mb-2">Prompt</label>
            <input id="prompt" v-model="form.prompt" type="text"
              class="p-3 rounded secondary-bg text-white border border-gray-700 focus:outline-none focus:ring-2 focus:ring-purple-500" />
          </div>

          <!-- Select Model (25% width) -->
          <div class="flex flex-col w-1/4">
            <label for="model" class="text-gray-300 mb-2">Select Model</label>
            <select id="model" v-model="form.model"
              class="p-3 rounded secondary-bg text-white border border-gray-700 focus:outline-none focus:ring-2 focus:ring-purple-500">
              <option value="model1">Model 1</option>
              <option value="model2">Model 2</option>
              <option value="model3">Model 3</option>
            </select>
          </div>
        </form>
        <button type="submit"
          class="w-1/4 px-12 py-3 bg-gradient-to-r from-purple-400 via-purple-600 to-purple-800 text-white rounded-[3px] font-medium hover:from-purple-800 hover:to-purple-400 focus:outline-none focus:ring-2 focus:ring-purple-500">
          Generate Image
        </button>
      </div>

      <div v-else class="px-8 pb-8 my-4 space-y-4 pt-8">
        <h1 class="text-2xl font-bold">Title of the Product</h1>
        <p class="text-gray-400">
          The Cordless Drill Kit is the ultimate solution for all your DIY projects and home improvement tasks.
          Powered by a robust 20V lithium-ion battery, this drill offers unmatched convenience, reliability, and
          power, allowing you to tackle everything from basic drilling to more advanced tasks with ease.
        </p>
        <button type="submit"
          class="w-1/4 px-12 py-3 bg-gradient-to-r from-purple-400 via-purple-600 to-purple-800 text-white rounded-[3px] font-medium hover:from-purple-800 hover:to-purple-400 focus:outline-none focus:ring-2 focus:ring-purple-500" @click="triggerFileInput">
          Upload File
        </button>
        <input
          type="file"
          id="image-upload"
          class="hidden"
          accept="image/*"
          @change="handleImageUpload"
          ref="fileInput"
        />

        <form @submit.prevent="handleSubmit" class="flex items-center space-x-4 pt-6">
          <!-- Prompt Field (50% width) -->
          <div class="flex flex-col w-1/2">
            <label for="recommendationChanges" class="text-gray-300 mb-2">Recommendation Changes</label>
            <input id="recommendationChanges" v-model="form.recommendationChanges" type="text"
              class="p-3 rounded secondary-bg text-white border border-gray-700 focus:outline-none focus:ring-2 focus:ring-purple-500" />
          </div>
          <!-- Select Model (25% width) -->
          <div class="flex flex-col w-1/4">
            <label for="category" class="text-gray-300 mb-2">Category</label>
            <select id="category" v-model="form.category"
              class="p-3 rounded secondary-bg text-white border border-gray-700 focus:outline-none focus:ring-2 focus:ring-purple-500">
              <option value="model1">Model 1</option>
              <option value="model2">Model 2</option>
              <option value="model3">Model 3</option>
            </select>
          </div>

          <!-- Select Model (25% width) -->
          <div class="flex flex-col w-1/4">
            <label for="model" class="text-gray-300 mb-2">Select Model</label>
            <select id="model" v-model="form.model"
              class="p-3 rounded secondary-bg text-white border border-gray-700 focus:outline-none focus:ring-2 focus:ring-purple-500">
              <option value="model1">Model 1</option>
              <option value="model2">Model 2</option>
              <option value="model3">Model 3</option>
            </select>
          </div>
        </form>
        <div class="mt-8 flex flex-col items-center w-2/3 border border-gray-700 rounded-[3px] p-4">
          <!-- Cropper -->
          <div v-if="uploadedImage" class="mb-4" style="max-width: 400px;">
            <Cropper
              v-model="crop"
              :src="uploadedImage"
              :stencil-props="{ aspectRatio: 4 / 3 }"
              :class="['cropper']"
              @change="updateCroppedImage"
            />
          </div>

          <!-- Placeholder -->
          <img
            v-else
            src="https://via.placeholder.com/400x300?text=No+Image+Uploaded"
            alt="Placeholder"
            class="w-full mb-4"
          />

          <!-- File Upload Input -->
          <input
            type="file"
            ref="fileInput"
            class="hidden"
            accept="image/*"
            @change="handleImageUpload"
          />

          <!-- Upload Button -->
          <button
            @click="triggerFileInput"
            class="px-12 py-3 bg-gradient-to-r from-purple-400 via-purple-600 to-purple-800 text-white rounded-[3px] font-medium hover:from-purple-800 hover:to-purple-400 focus:outline-none focus:ring-2 focus:ring-purple-500"
          >
            Generate Image
          </button>

          <!-- Generate Button -->
          <button
            v-if="croppedImage"
            @click="generateCroppedImage"
            class="mt-4 px-12 py-3 bg-gradient-to-r from-purple-400 via-purple-600 to-purple-800 text-white rounded-[3px] font-medium hover:from-purple-800 hover:to-purple-400 focus:outline-none focus:ring-2 focus:ring-purple-500"
          >
            Generate
          </button>

          <!-- Cropped Image Preview -->
          <div v-if="croppedImage" class="mt-4 border border-gray-700 rounded p-4 bg-white">
            <p class="text-center font-medium text-purple-700">Cropped Image:</p>
            <img :src="croppedImage" alt="Cropped Preview" class="w-full rounded" />
          </div>
        </div>


      </div>
    </div>
  </div>
</template>

<script>
import { Cropper } from "vue-advanced-cropper";
import "vue-advanced-cropper/dist/style.css";

export default {
  components: {
    Cropper,
  },
  data() {
    return {
      activeTab: 'generate',
      form: {
        productName: '',
        prompt: '',
        model: '',
        category: '',
        recommendationChanges: ''
      },
      uploadedImage: null, // Holds the base64 representation of the uploaded image
      crop: null, // Holds the crop data
      croppedImage: null, // Holds the cropped image
    };
  },
  methods: {
    handleSubmit() {
      console.log("Generating image with prompt:", this.form.prompt);
    },
    triggerFileInput() {
      this.$refs.fileInput.click();
    },
    handleImageUpload(event) {
      const file = event.target.files[0];
      if (file) {
        this.uploadedImage = URL.createObjectURL(file);
      }
    },
    updateCroppedImage(crop) {
      this.crop = crop;
    },
    generateCroppedImage() {
      if (this.crop?.canvas) {
        this.croppedImage = this.crop.canvas.toDataURL("image/jpeg");
      }
    },
  },
};
</script>
<style scoped>
.cropper {
  height: 300px;
  max-width: 400px;
  margin: 0 auto;
  border: 1px solid #ccc;
}
</style>