<template>
  <svg xmlns="http://www.w3.org/2000/svg" width="19" height="19" viewBox="0 0 19 19" fill="none">
    <path
      d="M7.91658 1.78125C7.58867 1.78125 7.32283 2.04708 7.32283 2.375V2.96875H3.95825C3.63033 2.96875 3.3645 3.23458 3.3645 3.5625C3.3645 3.89042 3.63033 4.15625 3.95825 4.15625H15.0416C15.3695 4.15625 15.6353 3.89042 15.6353 3.5625C15.6353 3.23458 15.3695 2.96875 15.0416 2.96875H11.677V2.375C11.677 2.04708 11.4112 1.78125 11.0833 1.78125H7.91658Z"
      fill="#CA1B1B" />
    <path
      d="M7.91658 8.43124C8.2445 8.43124 8.51033 8.69708 8.51033 9.02499L8.51033 14.5667C8.51033 14.8946 8.2445 15.1604 7.91658 15.1604C7.58867 15.1604 7.32283 14.8946 7.32283 14.5667L7.32283 9.02499C7.32283 8.69708 7.58867 8.43124 7.91658 8.43124Z"
      fill="#CA1B1B" />
    <path
      d="M11.677 9.02499C11.677 8.69708 11.4112 8.43124 11.0833 8.43124C10.7553 8.43124 10.4895 8.69708 10.4895 9.02499V14.5667C10.4895 14.8946 10.7553 15.1604 11.0833 15.1604C11.4112 15.1604 11.677 14.8946 11.677 14.5667V9.02499Z"
      fill="#CA1B1B" />
    <path fill-rule="evenodd" clip-rule="evenodd"
      d="M4.74313 6.26776C4.77654 5.96707 5.0307 5.73958 5.33325 5.73958H13.6666C13.9691 5.73958 14.2233 5.96707 14.2567 6.26776L14.4152 7.69383C14.7024 10.2789 14.7024 12.8878 14.4152 15.4728L14.3996 15.6132C14.2855 16.6393 13.4903 17.4576 12.4678 17.6007C10.4988 17.8764 8.50102 17.8764 6.53202 17.6007C5.50956 17.4576 4.71429 16.6393 4.60028 15.6132L4.58468 15.4728C4.29745 12.8878 4.29745 10.2789 4.58468 7.69383L4.74313 6.26776ZM5.86468 6.92708L5.76491 7.82497C5.48737 10.3229 5.48737 12.8438 5.76491 15.3417L5.78051 15.4821C5.83459 15.9687 6.21175 16.3568 6.69667 16.4247C8.55643 16.685 10.4434 16.685 12.3032 16.4247C12.7881 16.3568 13.1652 15.9687 13.2193 15.4821L13.2349 15.3417C13.5125 12.8438 13.5125 10.3229 13.2349 7.82497L13.1351 6.92708H5.86468Z"
      fill="#CA1B1B" />
  </svg>
</template>