export default {
  namespaced: true, // Important: Use namespaced to avoid conflicts
  state: {
    topics: {}, // State for generated topics
  },
  mutations: {
    // Set the entire topics object
    SET_TOPICS(state, topics) {
      state.topics = topics;
    },
    // Delete a topic and its subtopics
    DELETE_TOPIC(state, topic) {
      if (state.topics.result[topic]) {
        delete state.topics.result[topic]; // Delete the topic from the state
      }
    },
    // Delete a specific subtopic
    DELETE_SUBTOPIC(state, { topic, subIndex }) {
      console.log(topic, 'topic store')
      console.log(state.topics, 'state.topics')
      console.log(state.topics.result[topic], 'state.topics.result[topic] store')
      if (state.topics.result[topic]) {
        state.topics.result[topic].splice(subIndex, 1); // Remove the subtopic
        // If no subtopics remain, delete the topic
        if (state.topics.result[topic].length === 0) {
          delete state.topics.result[topic];
        }
      }
    },
  },
  actions: {
    // Set the topics via action
    setGeneratedTopics({ commit }, topics) {
      console.log(topics, 'setGeneratedTopics');
      commit("SET_TOPICS", topics);
    },
    // Action to delete a topic
    deleteTopic({ commit }, topic) {
      commit("DELETE_TOPIC", topic);
    },
    // Action to delete a subtopic
    deleteSubTopic({ commit }, { topic, subIndex }) {
      commit("DELETE_SUBTOPIC", { topic, subIndex });
    },
  },
  getters: {
    // Getter for the topics state
    topics: (state) => state.topics,
  },
};
