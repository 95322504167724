<template>
  <div class="secondary-bg p-4 rounded-lg flex items-center justify-between">
    <div>
      <h4 class="text-gray-400 text-sm">{{ title }}</h4>
      <p class="text-2xl font-bold">{{ value }}</p>
    </div>
    <div :class="isPositive ? 'text-green-500' : 'text-red-500'">
      <i :class="isPositive ? 'fas fa-arrow-up' : 'fas fa-arrow-down'"></i>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    title: String,
    value: String,
    isPositive: Boolean,
  },
};
</script>
