<template>
  <div>
    <canvas ref="lineChart"></canvas>
  </div>
</template>

<script>
import {
  Chart as ChartJS,
  Title,
  Tooltip,
  Legend,
  LineElement,
  PointElement,
  LineController,
  CategoryScale,
  LinearScale,
} from "chart.js";
import { onMounted, ref } from "vue";

// Register all required components, including the LineController
ChartJS.register(Title, Tooltip, Legend, LineElement, PointElement, LineController, CategoryScale, LinearScale);

export default {
  setup() {
    const lineChart = ref(null);

    onMounted(() => {
      const ctx = lineChart.value.getContext("2d");
      new ChartJS(ctx, {
        type: "line", // Specify the chart type
        data: {
          labels: ["Oct", "Nov", "Dec", "Jan", "Feb", "Mar"],
          datasets: [
            {
              label: "Achieved",
              data: [3, 5, 2, 8, 7, 9],
              borderColor: "rgba(75, 192, 192, 1)",
              tension: 0.4, // Smoothing for the line
              fill: false,
            },
            {
              label: "Target",
              data: [2, 4, 5, 6, 8, 10],
              borderColor: "rgba(255, 99, 132, 1)",
              tension: 0.4,
              fill: false,
            },
          ],
        },
        options: {
          responsive: true,
          maintainAspectRatio: false,
        },
      });
    });

    return {
      lineChart,
    };
  },
};
</script>

<style scoped>
canvas {
  max-width: 100%;
  max-height: 400px;
}
</style>
