<template>
  <div v-if="Object.keys(allTopics).length > 0" class="mt-10 mb-4 flex flex-col justify-center items-center">
    <div class="w-full">
      <div class="mb-8">
        <h1 class="text-lg font-bold text-white">Topics Generated</h1>
        <p class="text-gray-400 text-[0.875rem]">
          You can edit the topics or subtopics as per the need.
        </p>
        <p class="text-gray-400 mt-2 text-[0.875rem]">
          Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed do eiusmod tempor incididunt ut labore et
          dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip
          ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu
          fugiat nulla pariatur.
        </p>
      </div>
      <div class="grid grid-cols-2 gap-6">
        <div v-for="(subTopics, topic, index) in allTopics" :key="index" class="p-4 rounded-lg secondary-bg">
          <div class="flex justify-between items-center mb-2">
            <div v-if="editingTopicIndex === index">
              <input v-model="editingTopic" class="p-1 rounded" type="text" />
            </div>
            <div v-else>
              <span class="font-bold text-[1rem] text-white">{{ topic }}</span>
            </div>
            <div class="flex space-x-4">
              <button class="text-gray-400 hover:text-gray-300" @click="toggleEditTopic(index, topic)">
                <EditIcon v-if="editingTopicIndex !== index" />
                <span class="text-xs" v-else>Save</span>
              </button>
              <button class="text-red-500 hover:text-red-400" @click="deleteTopic(index)">
                <TrashIcon />
              </button>
            </div>
          </div>
          <hr class="border-black my-2" />
          <div class="pl-4">
            <div v-for="(subtopic, subIndex) in subTopics" :key="subIndex"
              class="flex justify-between items-center mb-2">
              <div v-if="editingSubTopicIndex === `${index}-${subIndex}`">
                <input v-model="editingSubTopic" class="p-1 rounded" type="text" />
              </div>
              <div v-else>
                <span class="text-[1rem] text-white">{{ subtopic }}</span>
              </div>
              <div class="flex space-x-4">
                <button class="text-gray-400 hover:text-gray-300"
                  @click="toggleEditSubTopic(index, subIndex, subtopic)">
                  <EditIcon v-if="editingSubTopicIndex !== `${index}-${subIndex}`" />
                  <span class="text-xs" v-else>Save</span>
                </button>
                <button class="text-red-500 hover:text-red-400" @click="deleteSubTopic(index, subIndex)">
                  <TrashIcon />
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="mt-8 flex">
        <button
          class="py-3 bg-gradient-to-r from-purple-400 via-purple-600 to-purple-800 text-white font-medium hover:from-purple-800 hover:to-purple-400 focus:outline-none focus:ring-2 focus:ring-purple-500 font-bold py-2 px-6 rounded-[3px]">
          Approve topics and subtopics
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
import TrashIcon from "../Icons/TrashIcon.vue";
import EditIcon from "../Icons/EditIcon.vue";

export default {
  components: {
    TrashIcon,
    EditIcon
  },
  data() {
    return {
      editingTopicIndex: null,
      editingTopic: "",
      editingSubTopicIndex: null,
      editingSubTopic: "",
    }
  },
  computed: {
    ...mapState("topics", ["topics"]),
    allTopics() {
      console.log(this.topics, 'allTopics');
      return this.topics?.result || [];
    },
  },
  methods: {
    toggleEditTopic(index, topic) {
      if (this.editingTopicIndex === index) {
        // Save changes
        const allTopicsKeys = Object.keys(this.allTopics);
        const newKey = this.editingTopic;
        const oldKey = allTopicsKeys[index];
        if (newKey && newKey !== oldKey) {
          this.$set(this.allTopics, newKey, this.allTopics[oldKey]);
          this.$delete(this.allTopics, oldKey);
        }
        this.editingTopicIndex = null;
        this.editingTopic = "";
      } else {
        // Enable editing
        this.editingTopicIndex = index;
        this.editingTopic = topic;
      }
    },
    toggleEditSubTopic(topicIndex, subIndex, subtopic) {
      const subKey = `${topicIndex}-${subIndex}`;
      if (this.editingSubTopicIndex === subKey) {
        // Save changes
        const allTopicsKeys = Object.keys(this.allTopics);
        const topicKey = allTopicsKeys[topicIndex];
        if (this.editingSubTopic) {
          this.allTopics[topicKey][subIndex] = this.editingSubTopic;
        }
        this.editingSubTopicIndex = null;
        this.editingSubTopic = "";
      } else {
        // Enable editing
        this.editingSubTopicIndex = subKey;
        this.editingSubTopic = subtopic;
      }
    },
    deleteTopic(topic) {
      this.$store.dispatch("topics/deleteTopic", topic);
    },
    deleteSubTopic(topic, subIndex) {
      this.$store.dispatch("topics/deleteSubTopic", { topic, subIndex });
    },
  }
};
</script>

<style scoped>
.secondary-bg {
  background-color: #2b2b2b;
}
</style>
