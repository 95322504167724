import { createRouter, createWebHistory } from "vue-router";
import LoginPage from '@/views/LoginPage.vue'
import DataUpload from '@/components/DataUpload.vue'
const routes = [
  {
    path: '/',
    name: "login",
    component: LoginPage
  },
  {
    path: '/data-upload',
    name: "dataUpload",
    component: DataUpload
  },
];

const router = createRouter({
  history: createWebHistory(),
  routes,
});

export default router;
