import { createStore } from "vuex";
import topics from "./modules/topics";

const store = createStore({
  modules: {
    topics, // Register the 'topics' module
  },
});

export default store;
