<template>
    <div>
      <canvas ref="barChart"></canvas>
    </div>
  </template>
  
  <script>
  import {
    Chart as ChartJS,
    Title,
    Tooltip,
    Legend,
    BarElement,
    BarController,
    CategoryScale,
    LinearScale,
  } from "chart.js";
  import { onMounted, ref } from "vue";
  
  // Register all required components, including the BarController
  ChartJS.register(Title, Tooltip, Legend, BarElement, BarController, CategoryScale, LinearScale);
  
  export default {
    setup() {
      const barChart = ref(null);
  
      onMounted(() => {
        const ctx = barChart.value.getContext("2d");
        new ChartJS(ctx, {
          type: "bar", // Specify the chart type
          data: {
            labels: ["Marketing", "Developers", "HR", "Design"],
            datasets: [
              {
                label: "Topics",
                data: [5, 10, 3, 8],
                backgroundColor: [
                  "rgba(75, 192, 192, 1)",
                  "rgba(255, 99, 132, 1)",
                  "rgba(255, 205, 86, 1)",
                  "rgba(54, 162, 235, 1)",
                ],
              },
            ],
          },
          options: {
            responsive: true,
            maintainAspectRatio: false,
            scales: {
              x: {
                grid: {
                  display: false,
                },
              },
              y: {
                ticks: {
                  beginAtZero: true,
                },
              },
            },
          },
        });
      });
  
      return {
        barChart,
      };
    },
  };
  </script>
  
  <style scoped>
  canvas {
    max-width: 100%;
    max-height: 400px;
  }
  </style>
  