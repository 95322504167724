<template>
  <div class="text-white min-h-screen">
    <!-- Main Grid -->
    <div class="flex gap-6">
      <!-- Left Section (70% Width) -->
      <div class="w-[70%]">
        <!-- Header Tiles -->
        <div class="grid grid-cols-3 gap-6 mb-8">
          <Tile title="Reviews" value="15K" isPositive="true" />
          <Tile title="Average Rating" value="4.55" isPositive="false" />
          <Tile title="Average Sentiment" value="39.55%" isPositive="true" />
        </div>

        <!-- Charts -->
        <div class="grid grid-cols-2 gap-6">
          <!-- Line Chart -->
          <div class="secondary-bg rounded-lg p-6">
            <h3 class="mb-4 text-xl font-semibold">Average Sentiment</h3>
            <LineChart />
          </div>

          <!-- Bar Chart -->
          <div class="secondary-bg rounded-lg p-6">
            <h3 class="mb-4 text-xl font-semibold">Topic</h3>
            <BarChart />
          </div>
        </div>
      </div>

      <!-- Right Section (30% Width) -->
      <div class="w-[30%] secondary-bg rounded-lg px-6 py-2">
        <h3 class="mb-4 text-[1.375rem] font-semibold">Reviews</h3>
        <div>
          <ReviewCard title="Review Title" content="Lorem ipsum dolor sit amet, consectetur adipiscing elit..." />
          <ReviewCard title="Review Title" content="Lorem ipsum dolor sit amet, consectetur adipiscing elit..." />
        </div>
      </div>
    </div>
    <div class="w-full flex-1 mt-8">
      <DataTable :rows="tableData" :headers="tableHeaders" :perPage="10" />
    </div>
  </div>
</template>

<script>
import Tile from "@/components/Dashboard/Tile.vue";
import LineChart from "@/components/Dashboard/LineChart.vue";
import BarChart from "@/components/Dashboard/BarChart.vue";
import ReviewCard from "@/components/Dashboard/ReviewCard.vue";
import DataTable from "@/components/DataTable.vue";

export default {
  data() {
    return {
      tableHeaders: {
        name: "Product Name",
        pros: "Pros",
        cons: "Cons",
        designRecommendation: "Design Recommendation",
      },
      tableData: [
        {
          name: "Smartphone X",
          pros: "High-resolution camera, Long battery life",
          cons: "Expensive, Limited storage options",
          designRecommendation: "Improve storage capacity, Add a budget-friendly variant",
        },
        {
          name: "Wireless Earbuds Y",
          pros: "Compact design, Excellent sound quality",
          cons: "Short battery life, Limited compatibility",
          designRecommendation: "Enhance battery life, Broaden device compatibility",
        },
        {
          name: "Laptop Z",
          pros: "Lightweight, High performance",
          cons: "Heating issues, Average display quality",
          designRecommendation: "Improve cooling system, Upgrade display resolution",
        },
        {
          name: "Gaming Console Pro",
          pros: "Wide game library, Fast performance",
          cons: "Bulky design, No portable option",
          designRecommendation: "Create a portable version, Reduce overall size",
        },
        {
          name: "Smartwatch Alpha",
          pros: "Stylish design, Accurate fitness tracking",
          cons: "Limited battery life, Expensive",
          designRecommendation: "Introduce affordable variants, Optimize battery performance",
        },
        {
          name: "Bluetooth Speaker Beta",
          pros: "Rich bass, Water-resistant",
          cons: "Limited range, Heavyweight",
          designRecommendation: "Improve Bluetooth range, Design a lighter model",
        },
        {
          name: "E-Reader Delta",
          pros: "E-ink display, Long battery life",
          cons: "No backlight, Limited storage",
          designRecommendation: "Add backlight feature, Expand storage options",
        },
        {
          name: "Robot Vacuum Cleaner",
          pros: "Efficient cleaning, Remote control",
          cons: "Noisy, Expensive replacement parts",
          designRecommendation: "Reduce noise levels, Lower spare part costs",
        },
        {
          name: "4K Television Ultra",
          pros: "High resolution, Great color accuracy",
          cons: "Reflective screen, Expensive",
          designRecommendation: "Reduce screen glare, Offer budget models",
        },
        {
          name: "Action Camera Hero",
          pros: "Durable, Waterproof",
          cons: "Short battery life, Overheating",
          designRecommendation: "Extend battery life, Optimize cooling system",
        },
        {
          name: "Electric Scooter Glide",
          pros: "Eco-friendly, Cost-effective",
          cons: "Low speed, Short battery range",
          designRecommendation: "Increase speed and battery range, Improve charging speed",
        },
        {
          name: "Smart Home Hub",
          pros: "Centralized control, Voice assistance",
          cons: "Limited device compatibility, High setup cost",
          designRecommendation: "Expand device compatibility, Lower initial cost",
        },
        {
          name: "Fitness Tracker Fit",
          pros: "Accurate metrics, Sleek design",
          cons: "Fragile build, No GPS",
          designRecommendation: "Improve durability, Add GPS functionality",
        },
        {
          name: "Kitchen Blender Max",
          pros: "Powerful motor, Multiple speed settings",
          cons: "Loud operation, Difficult to clean",
          designRecommendation: "Reduce noise levels, Simplify cleaning process",
        },
        {
          name: "Portable Power Bank",
          pros: "High capacity, Lightweight",
          cons: "Long charging time, Limited output ports",
          designRecommendation: "Introduce fast charging, Add more output ports",
        },
        {
          name: "Digital Camera Nova",
          pros: "High-quality photos, Easy to use",
          cons: "Bulky design, Limited low-light performance",
          designRecommendation: "Reduce size, Enhance low-light capabilities",
        },
        {
          name: "Standing Desk Pro",
          pros: "Ergonomic design, Adjustable height",
          cons: "Expensive, Difficult assembly",
          designRecommendation: "Simplify assembly process, Offer lower-cost models",
        },
        {
          name: "Electric Toothbrush Ultra",
          pros: "Effective cleaning, Long battery life",
          cons: "Expensive replacement heads, No travel case",
          designRecommendation: "Lower replacement head cost, Include a travel case",
        },
        {
          name: "Noise-Canceling Headphones",
          pros: "Excellent noise isolation, Comfortable fit",
          cons: "Expensive, Fragile hinges",
          designRecommendation: "Reinforce hinges, Introduce affordable models",
        },
        {
          name: "Streaming Device StreamX",
          pros: "Wide app support, Easy setup",
          cons: "Slow interface, Limited storage",
          designRecommendation: "Improve interface speed, Expand storage capacity",
        },
        {
          name: "Dishwasher CleanMaster",
          pros: "Energy-efficient, Quiet operation",
          cons: "High water usage, Limited load capacity",
          designRecommendation: "Optimize water usage, Increase load capacity",
        },
        {
          name: "VR Headset Immersive",
          pros: "Great graphics, Lightweight",
          cons: "Expensive, Short battery life",
          designRecommendation: "Introduce budget-friendly models, Extend battery life",
        },
        {
          name: "Car Dash Cam",
          pros: "Wide field of view, High-resolution recording",
          cons: "Poor low-light performance, Limited storage",
          designRecommendation: "Improve night vision, Expand storage options",
        },
        {
          name: "Smart Refrigerator",
          pros: "Energy-efficient, Integrated touchscreen",
          cons: "Expensive, Requires frequent updates",
          designRecommendation: "Reduce cost, Ensure longer support cycles",
        },
        {
          name: "Gaming Laptop Apex",
          pros: "High-performance GPU, Backlit keyboard",
          cons: "Heavy, Short battery life",
          designRecommendation: "Reduce weight, Extend battery life",
        },
        {
          name: "Air Purifier FreshAir",
          pros: "Quiet operation, Effective filtration",
          cons: "Expensive filters, Limited coverage",
          designRecommendation: "Lower filter costs, Expand coverage area",
        },
        {
          name: "Electric Kettle RapidBoil",
          pros: "Quick boiling, Auto shut-off",
          cons: "Small capacity, No temperature control",
          designRecommendation: "Increase capacity, Add temperature control settings",
        },
        {
          name: "Cordless Vacuum Vortex",
          pros: "Lightweight, Strong suction",
          cons: "Short battery life, Expensive filters",
          designRecommendation: "Extend battery life, Lower filter replacement cost",
        },
        {
          name: "3D Printer MakerPro",
          pros: "High precision, Large print area",
          cons: "Steep learning curve, Expensive filaments",
          designRecommendation: "Simplify user interface, Reduce filament costs",
        },
        {
          name: "Smart Thermostat EcoTemp",
          pros: "Energy-saving, Remote control",
          cons: "Complex installation, Limited compatibility",
          designRecommendation: "Simplify installation, Expand compatibility",
        },
      ],
    };
  },
  components: {
    Tile,
    LineChart,
    BarChart,
    ReviewCard,
    DataTable
  },
};
</script>