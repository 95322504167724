<template>
    <div class="p-4 secondary-bg text-white rounded-lg">
      <!-- Search Bar -->
      <div class="flex justify-between items-center mb-4">
        <input
          type="text"
          v-model="searchQuery"
          placeholder="Search..."
          class="w-1/3 p-2 rounded-lg bg-gray-800 text-white"
          @input="onSearch"
        />
      </div>
  
      <!-- Table -->
      <table class="w-full border-collapse border border-gray-700">
        <thead>
          <tr class="bg-gray-800">
            <th
              v-for="(header, key) in headers"
              :key="key"
              class="p-2 text-left cursor-pointer"
              @click="sortTable(key)"
            >
              {{ header }}
              <span v-if="sortKey === key">
                <i
                  :class="sortOrder === 'asc' ? 'fas fa-arrow-up' : 'fas fa-arrow-down'"
                ></i>
              </span>
            </th>
          </tr>
        </thead>
        <tbody>
          <tr
            v-for="(row, index) in paginatedData"
            :key="index"
            class="hover:bg-gray-700"
          >
            <td v-for="key in Object.keys(headers)" :key="key" class="p-2">
              {{ row[key] }}
            </td>
          </tr>
        </tbody>
      </table>
  
      <!-- Pagination -->
      <div class="flex justify-between items-center mt-4">
        <button
          class="p-2 bg-gray-700 rounded-md"
          :disabled="currentPage === 1"
          @click="goToPreviousPage"
        >
          Previous
        </button>
        <span>
          Page {{ currentPage }} of {{ totalPages }}
        </span>
        <button
          class="p-2 bg-gray-700 rounded-md"
          :disabled="currentPage === totalPages"
          @click="goToNextPage"
        >
          Next
        </button>
      </div>
    </div>
  </template>
  
  <script>
  export default {
    props: {
      rows: {
        type: Array,
        required: true,
      },
      headers: {
        type: Object,
        required: true,
      },
      perPage: {
        type: Number,
        default: 10,
      },
    },
    data() {
      return {
        searchQuery: "",
        currentPage: 1,
        sortKey: "",
        sortOrder: "asc", // or 'desc'
      };
    },
    computed: {
      filteredData() {
        if (!this.searchQuery) return this.rows;
        return this.rows.filter((row) =>
          Object.values(row)
            .join(" ")
            .toLowerCase()
            .includes(this.searchQuery.toLowerCase())
        );
      },
      sortedData() {
        if (!this.sortKey) return this.filteredData;
        return [...this.filteredData].sort((a, b) => {
          const result =
            a[this.sortKey] > b[this.sortKey]
              ? 1
              : a[this.sortKey] < b[this.sortKey]
              ? -1
              : 0;
          return this.sortOrder === "asc" ? result : -result;
        });
      },
      paginatedData() {
        const start = (this.currentPage - 1) * this.perPage;
        const end = start + this.perPage;
        return this.sortedData.slice(start, end);
      },
      totalPages() {
        return Math.ceil(this.sortedData.length / this.perPage);
      },
    },
    methods: {
      onSearch() {
        this.currentPage = 1; // Reset to the first page on search
      },
      sortTable(key) {
        if (this.sortKey === key) {
          this.sortOrder = this.sortOrder === "asc" ? "desc" : "asc";
        } else {
          this.sortKey = key;
          this.sortOrder = "asc";
        }
      },
      goToNextPage() {
        if (this.currentPage < this.totalPages) {
          this.currentPage++;
        }
      },
      goToPreviousPage() {
        if (this.currentPage > 1) {
          this.currentPage--;
        }
      },
    },
  };
  </script>
  
  <style scoped>
  table th,
  table td {
    border: 1px solid #4a5568;
  }
  </style>
  