<template>
  <div>
    <!-- Header Section -->
    <div>
      <h1 class="text-lg font-bold text-white">Media Upload</h1>
      <p class="text-sm text-gray-400">
        Add your documents here, and you can upload up to 5 files max
      </p>
    </div>

    <!-- Upload Section -->
    <div
      class="p-16 flex flex-col justify-center items-center border-2 border-dashed border-gray-500 secondary-bg rounded-lg mt-8">
      <img :src="uploadIcon" alt="Upload Icon" class="mb-4" />
      <p class="text-white text-lg mb-2">
        Drag your file(s) or
        <span class="text-purple-500 cursor-pointer hover:underline" @click="triggerFileInput">
          browse
        </span>
      </p>
      <p class="text-gray-400 text-sm mb-4">Max 10 MB files are allowed</p>

      <input type="file" id="file-upload" class="hidden" @change="handleFileUpload" ref="fileInput" />
    </div>

    <!-- Progress Bar -->
    <div v-if="uploading" class="flex flex-col justify-center items-center secondary-bg mt-8">
      <div class="relative w-full h-4 bg-gray-700 rounded-full overflow-hidden">
        <div class="absolute h-full bg-purple-500" :style="{ width: `${progress}%` }"></div>
      </div>
    </div>

    <!-- Table Section -->
    <div v-if="showTable" class="w-full mt-8 bg-black flex flex-col space-y-8">
      <div class="flex justify-center items-center">
        <div class="rounded-md overflow-hidden w-full">
          <table class="table-auto w-full border-collapse">
            <thead>
              <tr>
                <th class="bg-purple-600 text-white text-left px-4 py-2 text-[0.875rem]">
                  Product Category
                </th>
                <th class="bg-purple-600 text-white text-left px-4 py-2 text-[0.875rem]">
                  Product Name
                </th>
                <th class="bg-purple-600 text-white text-left px-4 py-2 text-[0.875rem]">
                  Review Title
                </th>
                <th class="bg-purple-600 text-white text-left px-4 py-2 text-[0.875rem]">
                  Total Review
                </th>
              </tr>
            </thead>
            <tbody>
              <tr class="secondary-bg text-gray-200" v-for="(data, index) in tableData" :key="index">
                <td class="text-left px-4 py-2 text-[0.875rem]">
                  {{ data['Product Category'] }}
                </td>
                <td class="text-left px-4 py-2 text-[0.875rem]">
                  {{ data['Product Name'] }}
                </td>
                <td class="text-left px-4 py-2 text-[0.875rem]">
                  {{ data['Review Title'] }}
                </td>
                <td class="text-left px-4 py-2 text-[0.875rem]">
                  {{ data['Total Reviews'] }}
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>


      <!-- Form Section -->
      <div class="flex flex-col justify-center items-center">
        <div class="w-full">
          <div class="flex flex-wrap mb-4">
            <div class="w-full md:w-1/3 pr-2 mb-4 md:mb-0">
              <label for="input1" class="text-white font-bold text-[0.875rem] mb-1 block">
                Enter Industry Name
              </label>
              <input id="input1" v-model="industryName" type="text" placeholder="Type here..."
                class="secondary-bg w-full px-4 py-2 text-white rounded-[3px] focus:outline-none focus:ring-2 focus:ring-purple-500 text-[0.875rem]" />
            </div>
            <div class="w-full md:w-1/3 px-2 mb-4 md:mb-0">
              <label for="input2" class="text-white font-bold text-[0.875rem] mb-1 block">
                Enter Company Name
              </label>
              <input id="input2" v-model="companyName" type="text" placeholder="Type here..."
                class="secondary-bg w-full px-4 py-2 text-white rounded-[3px] focus:outline-none focus:ring-2 focus:ring-purple-500 text-[0.875rem]" />
            </div>
            <div class="w-full md:w-1/3 pl-2 mb-4 md:mb-0">
              <label for="input4" class="text-white font-bold text-[0.875rem] mb-1 block">Select Review Columns</label>
              <input id="input4" v-model="selectedReview" type="text" placeholder="Type here..."
                class="secondary-bg w-full px-4 py-2 text-white  rounded-[3px] focus:outline-none focus:ring-2 focus:ring-purple-500 text-[0.875rem]" />
            </div>
          </div>
          <!-- Row 2 -->
          <div class="flex flex-wrap mb-4">
            <div class="w-full md:w-1/3 pr-2 mb-4 md:mb-0">
              <label for="input3" class="text-white font-bold text-[0.875rem] mb-1 block">
                Select ID Columns
              </label>
              <select id="input3" v-model="selectedProduct"
                class="custom-dropdown p-3 rounded text-white secondary-bg w-full px-4 py-2 rounded-[3px] focus:outline-none focus:ring-2 focus:ring-purple-500 text-[0.875rem]">
                <option value="" disabled>Select a Product</option>
                <option v-for="(item, index) in products" :key="index" :value="item">
                  {{ item }}
                </option>
              </select>
            </div>
            <div class="w-full md:w-1/3 px-2">
              <label for="input5" class="text-white font-bold text-[0.875rem] mb-1 block">Select Category for
                Recommendation
                Level</label>
              <select v-model="selectedCategory"
                class="custom-dropdown p-3 rounded secondary-bg w-full px-4 py-2 text-white rounded-[3px] focus:outline-none focus:ring-2 focus:ring-purple-500 text-[0.875rem]">
                <option value="" disabled>Select a Category</option>
                <option class="tooltip" :data-tooltip="item" v-for="(item, index) in categories" :key="index" :value="item">
                  {{ item }}
                </option>
              </select>
            </div>
          </div>

          <!-- Generate Topics Button -->
          <div class="flex">
            <button @click="generateTopics" :disabled="loading"
              class="px-12 py-3 bg-gradient-to-r from-purple-400 via-purple-600 to-purple-800 text-white rounded-[3px] font-medium hover:from-purple-800 hover:to-purple-400 focus:outline-none focus:ring-2 focus:ring-purple-500">
              {{ loading ? "Loading..." : "Generate Topics" }}
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import * as XLSX from "xlsx";
import axios from "axios";
import { mapActions } from "vuex";

export default {
  data() {
    return {
      uploadIcon: require("@/assets/img/fe/upload-icon.svg"),
      progress: 0,
      uploading: false,
      showTable: false,
      tableData: [],
      categories: [],
      products: [],
      industryName: "",
      companyName: "",
      selectedProduct: "",
      selectedReview: "",
      selectedCategory: "",
      file: null,
      loading: false,
      generatedTopics: []
    };
  },
  methods: {
    ...mapActions("topics", ["setGeneratedTopics"]), // Map the action from the topics module
    triggerFileInput() {
      this.$refs.fileInput.click();
    },
    handleFileUpload(event) {
      this.file = event.target.files[0];
      if (this.file) {
        const reader = new FileReader();
        reader.onload = (e) => {
          const data = new Uint8Array(e.target.result);
          const workbook = XLSX.read(data, { type: "array" });
          this.parseExcel(workbook);
        };
        reader.readAsArrayBuffer(this.file);
        this.startUpload();
      }
    },
    startUpload() {
      this.uploading = true;
      this.progress = 0;
      const interval = setInterval(() => {
        if (this.progress < 100) {
          this.progress += 10;
        } else {
          clearInterval(interval);
          setTimeout(() => {
            this.uploading = false;
            this.showTable = true;
          }, 100);
        }
      }, 100);
    },
    parseExcel(workbook) {
      const sheetName = workbook.SheetNames[0];
      const sheet = workbook.Sheets[sheetName];
      const jsonData = XLSX.utils.sheet_to_json(sheet, { header: 1 });

      const headers = jsonData[0];
      const data = jsonData.slice(1).map((row) => {
        const obj = {};
        headers.forEach((header, index) => {
          obj[header] = row[index] || "";
        });
        return obj;
      });
      const uniqueCategories = this.getUniqueCategories(data);
      this.categories = uniqueCategories

      const uniqueProducts = this.getUniqueProductIds(data);
      this.products = uniqueProducts

      console.log(this.products)
      this.tableData = data.slice(0, 7); // Take only the first 7 records
      console.log(this.tableData, 'this.tableData')
    },
    getUniqueCategories(data) {
      const categories = data.map(item => item.Category); // Extract all categories
      const uniqueCategories = [...new Set(categories)];  // Get unique categories
      return uniqueCategories;
    },
    getUniqueProductIds(data) {
      const products = data.map(item => item['Product Name']); // Extract all categories
      const uniqueProducts = [...new Set(products)];  // Get unique categories
      return uniqueProducts;
    },
    setTopicsFromApiResponse(response) {
      const parsedTopics = Object.entries(response.result).map(([topic, subtopics]) => ({
        name: topic,
        subtopics: subtopics,
      }));

      this.topics = parsedTopics; // Update the topics for rendering
    },
    async generateTopics() {
      if (!this.industryName || !this.companyName || !this.file) {
        alert("Please fill all required fields and upload a file.");
        return;
      }

      this.loading = true;
      const formData = new FormData();
      formData.append("industryName", this.industryName);
      formData.append("companyName", this.companyName);
      formData.append("product", this.selectedProduct);
      formData.append("review", this.selectedReview);
      formData.append("file", this.file);

      try {
        const response = await axios.post("https://pdgai-api-app-dev.azurewebsites.net/api/generate-topics", formData, {
          headers: { "Content-Type": "multipart/form-data" },
        });
        this.showTable = true;
        const topics = response?.data || [];

        this.setGeneratedTopics(topics);
      } catch (error) {
        console.error(error);
        alert("Failed to generate topics.");
      } finally {
        this.loading = false;
      }
    },
  },
};
</script>

<style scoped>
.custom-dropdown {
  padding-bottom: 9px;
}

.secondary-bg {
  background-color: #2b2b2b;
}
.tooltip {
  position: relative;
}

.tooltip:hover::after {
  content: attr(data-tooltip);
  position: absolute;
  left: 0;
  top: 100%;
  white-space: nowrap;
  background: #333;
  color: #fff;
  padding: 5px 10px;
  border-radius: 4px;
  z-index: 100;
  font-size: 0.75rem;
  width: max-content;
}

</style>
