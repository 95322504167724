<template>
  <div class="text-[0.875rem] border border-gray-700 p-4 rounded-sm mb-4">
    <h4 class="font-semibold">{{ title }}</h4>
    <p class="text-gray-400">{{ content }}</p>
  </div>
</template>

<script>
export default {
  props: {
    title: String,
    content: String,
  },
};
</script>
