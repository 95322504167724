import { createApp } from 'vue';
import App from './App.vue';
import store from "./store"; // Import your Vuex store
import router from './router'; // Import the router
import easySpinner from 'vue-easy-spinner';
import { SnackbarService } from "vue3-snackbar";
import "vue3-snackbar/styles";

import "./assets/css/tailwind.css";
import "./assets/css/global.css";

createApp(App)
    .use(router) // Use the router instance
    .use(easySpinner, {
        prefix: 'easy',
    })
    .use(SnackbarService)
    .use(store) // Attach Vuex store to the app
    .mount('#app');
